$primary: #C61D67; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
body {
	font-size: 20px;
}

.row {
	padding-top: 2em;
	padding-bottom: 2em;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 45px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}



.top-pad {
  padding-top: 3em;
}






/****** CUSTOM CSS ******/
a.btn {
  text-shadow:none;
  font-size: 0.9em;
  font-weight: normal;
  letter-spacing: 1px;
  font-style: italic;

}
.btn-success {
	background: $primary;
	border-color: darken($primary,20%)
}
h1,h2,h3,h4,h5 {
	color: $primary;
	text-shadow: -1px 1px 2px $blk;
}

h2 {
	font-size: 2.5em;
}

hr {
	width: 150px;
	border:none;
	border-bottom: 1px solid $primary;
}

strong {
	color: $primary;
	text-transform: uppercase;
	font-style: italic;
	font-size: 1.5em;
	border-bottom: 1px dashed $primary;
}
.skew {
  transform:skew(10deg,3deg);
}

.noPad {
  padding-left:0px;
  padding-right:0px;
}

.wht, .wht h1, .wht h2, .wht h3, .wht h4, .wht p {
  color:white;
}




/** USER CSS **/
.navbar {
  margin-bottom:0;
  border:none;
}

.navbar-brand .glyphicon {
  margin-right:6px;
}

.hero {
  background:url(../img/city_bg.jpg) no-repeat center;
  background-size:cover;
  border:none;
  margin-bottom: 0px;

	@media (max-width:1200px) {
		background-position: 0em 0em;
	}

  @media (max-width: 550px) {
		background-position: -14em 0em;
  }
}

@media (min-width:992px) {
  .hero .get-it {
    text-align:left;
    margin-top: 80px;
    padding-right:30px;
  }
}

@media (max-width:992px) {
  .hero .get-it {
    text-align:center;
  }
}

@media (max-width:992px) {
  .hero .phone-preview {
    text-align:center;
  }
}

.hero .get-it h1 {
	font-weight: bold;
	color:$primary;
	text-shadow: -2px 1px 0px rgba(255,255,255,0.5);
}


.hero .get-it p {
	font-weight: 400;
	font-size: 24px;
  color:#fff;
  text-shadow: 0px 0px 3px rgba(0,0,0,0.75);
  margin-bottom:40px;
}

.hero .get-it .btn {
  // margin-left:10px;
  // margin-bottom:10px;
  text-shadow:none;
  font-size: 0.9em;
  font-weight: normal;
  letter-spacing: 1px;
  font-style: italic;
}

div.iphone-mockup {
  position:relative;
  max-width:250px;
  margin:20px;
  display:inline-block;
}

.iphone-mockup img.device {
  width:100%;
  height:auto;
}

.iphone-mockup .screen {
  position:absolute;
  width:88%;
  height:77%;
  top:12%;
  border-radius:2px;
  left:6%;
  border:1px solid #444;
  background-color:#aaa;
  overflow:hidden;
  background:url(../img/screen-content-iphone-6.jpg);
  background-size:cover;
  background-position:center;
}

.iphone-mockup .screen:before {
  content:'';
  background-color:#fff;
  position:absolute;
  width:70%;
  height:140%;
  top:-12%;
  right:-60%;
  transform:rotate(-19deg);
  opacity:0.2;
}

.icon-feature {
  text-align:center;
}

.icon-feature .glyphicon {
  font-size:60px;
}

section.features {
  background-color: $primary;
  padding:40px 0;
  color:#fff;
}

.features h2 {
  color:#fff;
}

.features .icon-features {
  margin-top:15px;
}

.testimonials blockquote {
  text-align:center;
}

section.testimonials {
  margin:50px 0;
}

.site-footer {
  padding:20px 0;
  text-align:center;
}

@media (min-width:768px) {
  .site-footer h5 {
    text-align:left;
  }
}

.site-footer h5 {
  color:inherit;
  font-size:20px;
}

.site-footer .social-icons a:hover {
  opacity:1;
}

.site-footer .social-icons a {
  display:inline-block;
  width:32px;
  border:none;
  font-size:20px;
  border-radius:50%;
  margin:4px;
  color:#fff;
  text-align:center;
  background-color:#798FA5;
  height:32px;
  opacity:0.8;
  line-height:32px;
}

@media (min-width:768px) {
  .site-footer .social-icons {
    text-align:right;
  }
}

/** 3 IMG SECTION **/
#imgSection {
	.row {
		padding-bottom: 0px;
	}
	h2 {
		padding: 0em 2em;
		color: $wht;
		text-shadow: -3px 1px 1px $primary;
	}
}

#imgBig {
  background:linear-gradient(to left, rgba(50,50,50,0.5),rgba(50,50,50,0.5)),url(../img/img1.jpg) no-repeat center;
  background-size:cover;
  padding: 10em 0em;
}

#imgSml1 {
  background:linear-gradient(to left, rgba(50,50,50,0.5),rgba(50,50,50,0.5)),url(../img/img2.jpg) no-repeat center;
  background-size:cover;
  padding: 5em 0em;  
}

#imgSml2 {
  background:linear-gradient(to left, rgba(50,50,50,0.5),rgba(50,50,50,0.5)),url(../img/img3.jpg) no-repeat center;
  background-size:cover;
  padding: 5em 0em;  
}

.flex {
  display:flex;
}

.flexOne {
  flex:1;
}

.flexHalf {
  flex:0.5;
}

.flexAlign {
  display:flex;
  align-items:center;
  justify-content:center;
}


.gradient {
  background:linear-gradient(to right, rgba(50,50,50,0.5),rgba(50,150,50,0.5)),;
  display:block;
  height:100%;
}

/** 3 IMG SECTION END **/

/** FOOTER **/
.footer-basic {
  padding:40px 0;
  background-color:#ffffff;
  color:#4b4c4d;
}

.footer-basic ul {
  padding:0;
  list-style:none;
  text-align:center;
  font-size:18px;
  line-height:1.6;
  margin-bottom:0;
}

.footer-basic li {
  padding:0 10px;
}

.footer-basic ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.8;
}

.footer-basic ul a:hover {
  opacity:1;
}

.footer-basic .social {
  text-align:center;
  padding-bottom:25px;
}

.footer-basic .social > a {
  font-size:24px;
  width:40px;
  height:40px;
  line-height:40px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  border:1px solid #ccc;
  margin:0 8px;
  color:inherit;
  opacity:0.75;
}

.footer-basic .social > a:hover {
  opacity:0.9;
}

.footer-basic .copyright {
  margin-top:15px;
  text-align:center;
  font-size:13px;
  color:#aaa;
  margin-bottom:0;
}

.modal-title {
	color: $wht;
}

.logo {
	max-width: 200px;
	padding: 0.5em 0em;
}

@media (max-width: 1365px) {
	#imgSection {
		#imgBig {
			padding: 8em 0em;
		}
		#imgSml1,#imgSml2 {
			padding: 5em 0em;
		}
		h2 {
			font-size: 1.5em;
		}
	}
}

@media (max-width: 991px) {
	#imgSection {
		#imgBig {
			padding: 5em 0em;
		}
		#imgSml1,#imgSml2 {
			padding: 5em 0em;
		}
		h2 {
			font-size: 1.5em;
		}
	}
}


@media(max-width: 767px) {
	h2 {
		font-size: 1.5em;
	}

	.logo {
		max-width: 150px;
		margin-left: 1em;
	}
  .navbar-header a {
    float: left;
  } 

	.navbar-toggle {
		margin-top: 20px;
	}

  .navbar .navbar-nav > li > a {
  	font-size: 0.8em;
  	height: 40px;
  }

  .icon-feature {
  	p {
  		font-size: 0.75em;
  	}
  }
}

